import { useEffect, useState } from 'react'
import {
	Button,
	Flex,
	Text,
	Image,
	useColorModeValue,
	useDisclosure,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'
import { useIntl } from 'react-intl'

import { useAppSelector } from '../../../hooks'
import {
	appInitializedState,
	circlesBreakpointState,
	landscapeOrientationState,
	confModeState,
	showBlockRippleState,
} from '../../../state'
import { TCConfModeModal } from '../../features'
import {
	blurInAnimation,
	PADDING_BREAKPOINTS,
	BITCOIN_ORANGE,
	menuLabelFontSize,
} from '../../../constants'
import { getBreakpointValue } from '../../../utils'
import { Label } from '../../shared'

export const TCFooter = () => {
	const [mt, setMt] = useState(0)
	const intl = useIntl()
	const { userLocale } = useAppSelector(({ settings }) => settings)
	const appInitialized = useRecoilValue(appInitializedState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const confMode = useRecoilValue(confModeState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const color = useColorModeValue('black', 'white')
	const colorAlt3 = useColorModeValue('rgba(0,0,0,0.3)', 'rgba(255,255,255,0.15)')
	const versionColor = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const colorAlt = useColorModeValue('rgba(0,0,0,0.7)', 'rgba(255,255,255,0.7)')
	const {
		isOpen: isOpenConfMode,
		onOpen: onOpenConfMode,
		onClose: onCloseConfMode,
	} = useDisclosure()
	const labelFontSize = getBreakpointValue(menuLabelFontSize, circlesBreakpoint)
	const px = getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
	const versionNum = process.env.REACT_APP_VERSION
	const justify = landscapeOrientation ? 'space-between' : 'center'
	const height = getBreakpointValue({ base: '30px', lg: '40px' }, circlesBreakpoint)
	const opacity = showBlockRipple ? 0.3 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'

	// NEW LOCALE UPDATE HERE
	const AR = intl.formatMessage({ id: 'i18n.aria_label.ar' }).toUpperCase()
	const CA = intl.formatMessage({ id: 'i18n.aria_label.ca' }).toUpperCase()
	const DE = intl.formatMessage({ id: 'i18n.aria_label.de' }).toUpperCase()
	const EN = intl.formatMessage({ id: 'i18n.aria_label.en' }).toUpperCase()
	const ES = intl.formatMessage({ id: 'i18n.aria_label.es' }).toUpperCase()
	const FR = intl.formatMessage({ id: 'i18n.aria_label.fr' }).toUpperCase()
	const GR = intl.formatMessage({ id: 'i18n.aria_label.gr' }).toUpperCase()
	const IT = intl.formatMessage({ id: 'i18n.aria_label.it' }).toUpperCase()
	const NL = intl.formatMessage({ id: 'i18n.aria_label.nl' }).toUpperCase()
	const PL = intl.formatMessage({ id: 'i18n.aria_label.pl' }).toUpperCase()
	const PT = intl.formatMessage({ id: 'i18n.aria_label.pt' }).toUpperCase()
	const RU = intl.formatMessage({ id: 'i18n.aria_label.ru' }).toUpperCase()
	const SV = intl.formatMessage({ id: 'i18n.aria_label.sv' }).toUpperCase()
	const TR = intl.formatMessage({ id: 'i18n.aria_label.tr' }).toUpperCase()
	const ZH = intl.formatMessage({ id: 'i18n.aria_label.zh' }).toUpperCase()

	// NEW LOCALE UPDATE HERE
	const LANGUAGE_FLAG_MAP = {
		ar: '🇸🇦',
		ca: <Image
			src="/images/flags/catalan-current.png"
			htmlHeight="9px"
			htmlWidth="18px"
			mr={1}
		/>,
		de: '🇩🇪',
		en: '🇺🇸',
		es: '🇪🇸',
		fr: '🇫🇷',
		gr: '🇬🇷',
		it: '🇮🇹',
		nl: '🇳🇱',
		pl: '🇵🇱',
		pt: '🇧🇷',
		ru: '🇷🇺',
		sv: '🇸🇪',
		tr: '🇹🇷',
		zh: '🇨🇳',
	}

	// NEW LOCALE UPDATE HERE
	const LANGUAGE_LABEL_MAP = {
		ar: AR,
		ca: CA,
		de: DE,
		en: EN,
		es: ES,
		fr: FR,
		gr: GR,
		it: IT,
		nl: NL,
		pl: PL,
		pt: PT,
		ru: RU,
		sv: SV,
		tr: TR,
		zh: ZH,
	}

	const LanguageLabel = () => {
		const flag = LANGUAGE_FLAG_MAP[userLocale]
		const label = LANGUAGE_LABEL_MAP[userLocale]
		return (
			<Flex
				align="center"
				gap={1}
			>
				{flag}

				<Label
					fontWeight="bold"
					mr="4px"
					fontSize={labelFontSize}
					color={colorAlt}
					transition="all 0.21s ease"
					_groupHover={{
						color: BITCOIN_ORANGE,
					}}
				>
					{label}
				</Label>
			</Flex>
		)
	}

	useEffect(() => {
		if (!landscapeOrientation) {
			const marginTop = getBreakpointValue({ base: 4, sm: 6, lg: 8 }, circlesBreakpoint) as number
			if (marginTop) setMt(marginTop)
		}
	}, [circlesBreakpoint, landscapeOrientation])

	return (
		<Flex
			className="tc-footer"
			flex="0 0 auto"
			pos="relative"
			zIndex={999}
			w="100%"
			h={height}
			color={color}
			justify={justify}
			align="center"
			gap={6}
			px={px}
			mt={mt}
			opacity={opacity}
			filter={filter}
			animation={blurInAnimation}
		>
			{appInitialized && confMode && (
				<>
					<Button
						onClick={onOpenConfMode}
						color={colorAlt3}
						borderColor={colorAlt3}
						variant="outline"
						size="xs"
						borderWidth={2}
						transition="all 0.21s ease"
						_hover={{
							borderColor: BITCOIN_ORANGE,
							color: BITCOIN_ORANGE,
						}}
						_active={{
							borderColor: BITCOIN_ORANGE,
							color: BITCOIN_ORANGE,
							bgColor: 'whiteAlpha.500',
						}}
					>
						CONF
					</Button>

					<TCConfModeModal
						isOpenConfMode={isOpenConfMode}
						onCloseConfMode={onCloseConfMode}
					/>
				</>
			)}

			{appInitialized && (
				<>
					{!confMode && (
						<LanguageLabel />
					)}
					
					<Text
						display="inline-block"
						textAlign="center"
						fontWeight="bold"
						fontSize="10px"
						color={versionColor}
						m={0}
					>
						v{versionNum}
					</Text>
				</>
			)}
		</Flex>
	)
}
