import {
	useEffect,
	useState,
} from 'react'
import {
	Flex,
	Box,
	useToken,
	useColorModeValue,
	useColorMode,
} from '@chakra-ui/react'
import { useRecoilValue } from 'recoil'

import { HeaderMenu } from './HeaderMenu'
import {
	BITCOIN_ORANGE,
	PADDING_BREAKPOINTS,
	PADDING_BREAKPOINTS_PORTRAIT,
	blurInAnimation,
} from '../../../constants'
import {
	appInitializedState,
	confModeState,
	circlesBreakpointState,
	showBlockRippleState,
	landscapeOrientationState,
	confBgImgState,
} from '../../../state'
import { getBreakpointValue } from '../../../utils'
import { MoonIcon, SunIcon } from '../../svg'
// import { DevHeader } from './DevHeader'

const HeaderColorModeButton = () => {
	const { colorMode, toggleColorMode } = useColorMode()
	const color = useColorModeValue('rgba(0,0,0,0.5)', 'rgba(255,255,255,0.5)')
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const opacity = showBlockRipple ? 0.21 : 1
	const filter = showBlockRipple ? 'blur(4px)' : 'blur(0)'
	const iconSize = getBreakpointValue({ base: '14px', lg: '16px' }, circlesBreakpoint)

	return (
		<Box
			onClick={toggleColorMode}
			cursor="pointer"
			color={color}
			animation={blurInAnimation}
			filter={filter}
			opacity={opacity}
			transition="all 0.21s ease"
			_hover={{
				color: BITCOIN_ORANGE,
			}}
		>
			{colorMode === 'light' ? <SunIcon width={iconSize} /> : <MoonIcon width={iconSize} />}
		</Box>
	)
}

export const TCHeader = () => {
	const [isScrolled, setIsScrolled] = useState(false)
	const [blackAlpha300, gray700] = useToken('colors', ['blackAlpha.300', 'gray.700'])
	const appInitialized = useRecoilValue(appInitializedState)
	const confMode = useRecoilValue(confModeState)
	const confBgImg = useRecoilValue(confBgImgState)
	const landscapeOrientation = useRecoilValue(landscapeOrientationState)
	const circlesBreakpoint = useRecoilValue(circlesBreakpointState)
	const showBlockRipple = useRecoilValue(showBlockRippleState)
	const height = getBreakpointValue({ base: '30px', lg: '40px' }, circlesBreakpoint)
	const bgColor = useColorModeValue('#eee', 'black')
	const borderC = useColorModeValue('#eee', 'black')
	const isConfBackground = confMode && confBgImg
	const px = landscapeOrientation
		? getBreakpointValue(PADDING_BREAKPOINTS, circlesBreakpoint)
		: getBreakpointValue(PADDING_BREAKPOINTS_PORTRAIT, circlesBreakpoint)
	const bg = showBlockRipple || isConfBackground
		? 'transparent'
		: bgColor
	const headerMb = getBreakpointValue({ base: 0, sm: 0 }, circlesBreakpoint)
	const border = showBlockRipple || isConfBackground
		? 'transparent'
		: borderC
	const scrolledBorder = useColorModeValue(blackAlpha300, gray700)
	const borderColor = isScrolled
		? scrolledBorder
		: border

	useEffect(() => {
		document.addEventListener('scroll', () => {
			const scrollPosition = window.scrollY
			if (scrollPosition > 0) {
				setIsScrolled(true)
			} else {
				setIsScrolled(false)
			}
		})
	}, [])

	return (
		<>
			<Flex
				className="tc-header-buffer"
				flex="0 0 auto"
				w="100%"
				h={height}
				mb={headerMb}
			/>

			<Flex
				className="tc-header"
				flex="0 0 auto"
				zIndex={999}
				pos="fixed"
				top={0}
				mt="env(safe-area-inset-top)"
				w="100%"
				h={height}
				align="center"
				fontSize="14px"
				px={px}
				gap={2}
				bg={bg}
				borderBottomWidth={1}
				borderColor={borderColor}
			>
				<HeaderMenu />

				{appInitialized && !confMode && (
					<>
						{/* <DevHeader /> */}

						<HeaderColorModeButton />
					</>
				)}
			</Flex>
		</>
	)
}
